/* eslint-disable class-methods-use-this, import/no-extraneous-dependencies */
import useJwt from '@/auth/jwt/useJwt'

export default class SmartReportService {
  static getReports() {
    return useJwt.get('/smart-report/reports', {}, {})
  }

  static getReport(type, filters) {
    return useJwt.post(`/smart-report/report/${type}`, {}, filters)
  }
}
