<template>
  <section id="report">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-card-actions
      ref="filters"
      title="Filtros"
      class="form-filters mt-1"
      :action-close="false"
      :action-refresh="false"
      :action-collapse="true"
    >
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Relatório"
          >
            <b-form-select
              v-model="selectedReport"
              :options="reports"
              @change="changeReport"
            />
          </b-form-group>
        </b-col>

        <b-col v-if="selectedReport">
          <p class="mt-2">
            {{ reports.find(f => f.value == selectedReport).description }}
          </p>
        </b-col>
      </b-row>

      <b-row v-if="selectedReport">
        <b-col
          v-for="item in filters[selectedReport]"
          :key="item.id"
          cols="3"
        >
          <b-form-group
            :label="item.text"
            :label-for="item.id"
          >
            <b-form-input
              v-if="item.type === 'input'"
              :id="item.id"
              v-model="filtersValues[item.id]"
            />

            <b-form-select
              v-if="item.type === 'select'"
              :id="item.id"
              v-model="filtersValues[item.id]"
              :options="item.options"
            />

            <flat-pickr
              v-if="item.type === 'date'"
              :id="item.id"
              :ref="item.id"
              v-model="filtersValues[item.id]"
              class="form-control"
              :config="pickrConfig"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="3"
          class="form-buttons"
        >
          <b-form-group
            label=""
            label-for="btn-buscar"
            style="margin-top: 20px"
          >
            <b-button
              id="btn-buscar"
              variant="primary"
              @click="getReport()"
            >
              Buscar
            </b-button>

            <b-button
              id="btn-limpar"
              variant="outline-secondary"
              @click="reset()"
            >
              Limpar
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card
      ref="results"
      title="Resultados"
    >
      <b-row v-if="results.length > 0">
        <b-col class="text-right">
          <span>Total: {{ results.length }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            hover
            responsive
            :busy="isLoading"
            :items="results"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            show-empty
          >
            <template #empty>
              <h4>Nenhuma dado encontrado</h4>
            </template>

            <template #table-busy>
              <div class="text-center table-spinner">
                <b-spinner label="Loading..." />
                <strong>Carregando...</strong>
              </div>
            </template>

            <template #head()="scope">
              <div class="text-nowrap">
                {{ headers.find(f => (f.value === scope.column)).text }}
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BAvatar,
  BBreadcrumb,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPaginationNav,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { formatDateTimeDbToView } from '@/utils/helpers'
import SmartReportService from '@/services/smartReportService'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import toast from '@/mixins/toast'

const components = {
  BAvatar,
  BBreadcrumb,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BPaginationNav,
  BRow,
  BSpinner,
  BTable,
  BCardActions,
  flatPickr,
}

export default {
  components,

  mixins: [
    toast,
  ],
  data() {
    return {
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Relatórios',
          active: true,
        },
        {
          text: 'Relatórios Root',
          active: true,
        },
      ],

      pickrConfig: {
        mode: 'single',
        // noCalendar: true,
        // inline: true,
        allowInput: true,
        position: 'auto center',
        // enableTime: true,
        dateFormat: 'Y-m-d H:i:s',
        altFormat: 'd/m/Y',
        altInput: true,
        // minDate: new Date().setMinutes(Math.floor((new Date()).getMinutes() / 5) * 5),
        time_24hr: true,
        locale: Portuguese,
      },

      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',

      headers: [],
      fields: [],
      reports: [],
      filters: {},

      filtersValues: {},
      selectedReport: null,

      results: [],
      formatDateTimeDbToView,
      isLoading: false,
    }
  },

  created() {
    this.getReports()
  },

  methods: {
    changeReport(selected) {
      const newFilters = {}
      this.filtersValues = {}
      this.filters[selected].forEach(item => {
        newFilters[item.id] = ''
      })

      this.filtersValues = newFilters
    },

    getReport() {
      this.isLoading = true
      this.results = []

      SmartReportService.getReport(this.selectedReport, this.filtersValues).then(response => {
        if (response.data !== null) {
          this.headers = response.data.data.header
          this.fields = response.data.data.header.map(m => ({
            key: m.value,
            sortable: true,
            thStyle: 'vertical-align: middle; width: 200px;',
          }))
          // { key: 'date', sortable: true, thStyle: 'vertical-align: middle; width: 200px;' },
          this.results = response.data.data.report
        }
      }).finally(() => {
        this.isLoading = false
      }).catch(response => {
        this.toastDanger('Ops!', response.response.data.errors[1] ?? response.response.data.errors.join('\n'))
      })
    },

    getReports() {
      this.isLoading = true
      SmartReportService.getReports().then(response => {
        if (response.data !== null) {
          this.filters = response.data.data.filters
          this.reports = response.data.data.reports
        }
      }).catch(response => {
        if (response.response.data.errors) {
          this.toastDanger('Ops!', response.response.data.errors[1] ?? response.response.data.errors.join('\n'))
        }
      }).finally(() => {
        this.isLoading = false
      })
    },

    reset() {
      const newFilter = {}
      Object.entries(this.filtersValues).forEach(item => {
        newFilter[item[0]] = ''
      })
      this.filtersValues = newFilter
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
